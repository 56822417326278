.title {
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
  // font-size: 32px;
}
.description {
  margin-top: 24px;
  // font-size: 16px;
  color: #8d8a8b;
  text-align: center;
  padding: 0 50px;
  .red {
    color: #8b0029;
  }
}
.directions {
  margin-top: 60px;
  
  .direction {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 992px) {
      margin-bottom: 60px;
    }
    .dTitle {
      font-weight: bold;
      font-size: 18px;
      color: rgb(94,94,94);
      padding: 0px 10px;
      line-height: 1.5em;
      width: 250px;
      margin-bottom: 0px;
      text-align: center;
    }
    h6 {
      margin-top: 12px;
      text-align: center;
      padding: 0 30px;
      word-break: keep-all;
      font-size: 14px;
      @media screen and (max-width: 576px) {
        padding: 0 10px;
        font-size: 12px;
      }
    }
    img {
      margin-top: 3rem;
      width: 50%;
    }
  }
}
