@import "~bootstrap/scss/bootstrap";
@import "./font.scss";
body {
  font-family: "Mulish", sans-serif;
  word-break: keep-all;
}
.therapeutics{
  background-color: rgb(164,91,106)
}

.morphogenic{
  background-color: rgb(231,140,54)
}

.knowingdoctor{
  background-color: rgb(141,203,250);
}
.color-bar{
  height: 100%;
  width: 10px;
}
a {
  text-decoration: none;
}
.footer {
  width: 100%;
  background-color: #80797b;
  color: white;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    width: 80%;
    margin: auto;
    font-size: 16px;
    margin: 0;
    text-align: center;
    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
    @media screen and (max-width: 576px) {
      font-size: 12px;
    }
    .contour {
      margin: 0 8px;
    }
  }
}
.navbar-nav {
  a {
    cursor: pointer;
    margin-right: 18px;
    &:hover {
      color: #8b0029 !important;
    }
    &:focus {
      color: #8b0029 !important;
    }
  }
}

@media screen and (max-width: 992px) {
  .row {
    gap: 12px;
  }
}
.navbar-nav {
  @media screen and (max-width: 992px) {
    a {
      padding: 12px 12px;
      font-weight: 700;
    }
  }
}
