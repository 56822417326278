@keyframes fromRight-10 {
  0% {
    opacity: 0;
    right: -30px;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    right: 0px;
  }
}

@keyframes fromBottom-30 {
  0% {
    opacity: 0;
    bottom: -30px;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}
@keyframes slideDown {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@keyframes slideUp {
  0% {
    height: 100%;
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
